<template>
  <div id="designList">
    <h1>My Designs</h1>

    <p>
      <router-link to="/dashboard/new">New Design</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'designList',
  components: {
  },
  methods: {
  }
}
</script>
